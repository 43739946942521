/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    stopwatch: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.5 5.6a.5.5 0 10-1 0v2.9h-3a.5.5 0 000 1H8a.5.5 0 00.5-.5z"/><path pid="1" d="M6.5 1A.5.5 0 017 .5h2a.5.5 0 010 1v.57c1.36.196 2.594.78 3.584 1.64l.012-.013.354-.354-.354-.353a.5.5 0 01.707-.708l1.414 1.415a.5.5 0 11-.707.707l-.353-.354-.354.354-.013.012A7 7 0 117 2.071V1.5a.5.5 0 01-.5-.5M8 3a6 6 0 10.001 12A6 6 0 008 3"/>',
    },
});
